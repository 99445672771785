/**
 * Module: Hubspot Chat Widget
 * --------------------------------------------------
 * Description:
 * This module is responsible for initializing the Hubspot Chat Widget, and associating
 * the current user with the chat widget (if they are logged in).
 */
const onConversationsAPIReady = () => {
  window.HubSpotConversations.widget.load();
};

const initializeHubspotChatWidget = () => {
  fetch('/hubspot/generate_token')
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network error');
      }
      return response.json();
    })
    .then((data) => {
      const { visitor_email, visitor_token } = data;
      window.hsConversationsSettings = {
        identificationEmail: visitor_email,
        identificationToken: visitor_token,
      };

      if (window.HubSpotConversations) {
        onConversationsAPIReady();
      } else {
        window.hsConversationsOnReady = [onConversationsAPIReady];
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

export default initializeHubspotChatWidget;
