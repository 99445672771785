import { createTheme } from '@mui/material/styles';

// TODO: we have some global css overrides, since our pages use both
// MUI and Bootstrap 5 for styling. We should consolidate the two, or
// even better, remove Bootstrap 5 entirely and rewrite our navigation menus
// to use MUI components.

export const themeAttuned = createTheme({
  // https://mui.com/material-ui/customization/palette
  palette: {
    //
    // ---- Official palette (primary, secondary, error, warning, info, success) ----
    //
    primary: {
      main: '#346189',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#e3ebf0',
      contrastText: '#193962',
    },
    // error: {}
    // warning: {}
    // info: {}
    // success: {}

    //
    // ---- Custom colors ----
    //
    // Note: tokens for custom colors are not automatically calculated.
    brand: {
      main: '#67BBB9',
      dark: '#488281',
      light: '#85C8C7',
      contrastText: '#fff',
    },
    text: {
      main: '#193962',
    },
  },

  // component overrides
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          fontWeight: 400,
          '&:hover': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
          },
        },
        containedSecondary: {
          color: '#193962',
          backgroundColor: '#e3ebf0',
          '&:hover': {
            backgroundColor: '#dce3e8',
          },
          '& .MuiTouchRipple-root': {
            color: '#b3bcc5',
          },
          '&.Mui-disabled': {
            backgroundColor: '#e3ebf0',
            color: '#9a9a9a',
            opacity: 0.5,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root:last-child td, & .MuiTableRow-root:last-child th': {
            border: 0,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          // Horizontal padding aligns with <DialogContent> padding
          padding: '12px 24px 12px 24px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: '500',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.severity === 'success' &&
            ownerState.variant === 'filled' && {
              backgroundColor: '#67BBB9',
              color: '#ffffff',
            }),
        }),
      },
    },
  },
});

export const transparentButtonSX = {
  backgroundColor: 'transparent',
  boxShadow: 'none',
  color: 'initial',
  ':hover': {
    color: 'initial',
    backgroundColor: 'initial',
    boxShadow: 'none',
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
  disableRipple: true,
};
