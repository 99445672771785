import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { AppBar } from '@mui/material';
import Alert from '@mui/material/Alert';

const MimicBanner = ({ name, email, stopMimickUrl, offset }) => {
  const BannerBar = styled(AppBar)({
    height: '50px',
    width: '100%',
    zIndex: 1020,
    position: 'fixed',
    top: `${offset}px`,
  });
  return (
    <BannerBar className="no-print">
      <Alert
        severity="warning"
        variant="filled"
        sx={{
          borderRadius: 0,
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          '& .MuiAlert-action': {
            paddingTop: 0,
            alignSelf: 'center',
          },
        }}
        action={
          <Button size="small" variant="contained" color="secondary" href={stopMimickUrl} data-method="post" rel="nofollow">
            Stop Mimicking
          </Button>
        }
      >
        MIMICKING: {name} ({email})
      </Alert>
    </BannerBar>
  );
};

MimicBanner.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  stopMimickUrl: PropTypes.string,
  offset: PropTypes.number,
};

export default MimicBanner;
