import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material/styles';
import { createApi } from '../../apis/acknowledgements/api';
import { trackCzEvent } from '../../helpers/track_cz_event';

const RequiredAcknowledgementEvents = ({ raes }) => {
  const [initialRaes, setIniatialRaes] = useState(raes);
  const [acknowledgedRaes, setAcknowledgedRaes] = useState([]);

  let raeNames;
  if (raes.length > 2) {
    const raeNamesMinusLast = raes
      .slice(0, -1)
      .map((rae) => rae.display_name)
      .join(', ');
    raeNames = `${raeNamesMinusLast}, and ${raes[raes.length - 1].display_name}`;
  } else {
    raeNames = raes.map((rae) => rae.display_name).join(' and ');
  }

  const title = `To continue using Attuned's software products, please review and agree to the ${raeNames} below.`;
  const api = createApi('/acknowledgements');

  const refuse = async () => {
    const response = await api.refuse();
    trackCzEvent('User Ack Rejected', raes.map((rae) => rae.internal_name).join(', '), raes.length);
    window.location = response.redirect_to;
  };

  const accept = async () => {
    const response = await api.create({
      acknowledgement: {
        required_acknowledgement_event_ids: acknowledgedRaes,
      },
    });
    trackCzEvent('User Ack Accepted', raes.map((rae) => rae.internal_name).join(', '), raes.length);
    setIniatialRaes(response.raes);
  };

  const toggleRae = (id) => {
    if (acknowledgedRaes.includes(id)) {
      const index = acknowledgedRaes.indexOf(id);
      const newAcknowledgements = [...acknowledgedRaes];
      newAcknowledgements.splice(index, 1);
      setAcknowledgedRaes(newAcknowledgements);
    } else {
      setAcknowledgedRaes(acknowledgedRaes.concat(id));
    }
  };

  return (
    <Dialog
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      open={initialRaes.length > 0}
      maxWidth="xs"
    >
      <DialogTitle id="confirmation-dialog-title">
        <Stack direction="row" spacing={1} alignItems="start">
          <Stack direction="column">
            <Typography variant="h6" fontWeight="bolder" align="center" sx={{ color: '#0C2C57' }}>
              {title}
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <List>
          {initialRaes.map((rae) => (
            <ListItem key={rae.id}>
              <Checkbox checked={acknowledgedRaes.includes(rae.id)} onClick={(e) => toggleRae(rae.id)} />
              <div dangerouslySetInnerHTML={{ __html: rae.text }} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: 3, display: 'block' }}>
        <Stack justifyContent="center" alignItems="center" sx={{ width: 184, margin: 'auto' }} spacing={1}>
          <Button
            fullWidth
            variant="contained"
            onClick={accept}
            disabled={acknowledgedRaes.length < initialRaes.length}
            sx={{ backgroundColor: '#346189', textTransform: 'none' }}
          >
            Submit
          </Button>
          <Link
            onClick={refuse}
            variant="text"
            sx={{
              cursor: 'pointer',
              textTransform: 'none',
              color: '#346189',
              textDecoration: 'none',
            }}
          >
            Dismiss & Return
          </Link>
        </Stack>
        <Typography align="center" sx={{ marginTop: 2, fontSize: '.8rem', paddingLeft: 4, paddingRight: 4, color: 'grey' }}>
          If you have questions, please contact support at{' '}
          <a style={{ color: 'grey' }} href="mailto:support@attunedpartners.com">
            support@attunedpartners.com
          </a>{' '}
          or use the chat icon on the bottom right of the screen.
        </Typography>
      </DialogActions>
    </Dialog>
  );
};

export default RequiredAcknowledgementEvents;
