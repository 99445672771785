import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import { AppBar, Toolbar, Box, Stack, Link } from '@mui/material';
import GlobalAccount from './global_account';
import MimicBanner from './mimic_banner';
import StagingBanner from './staging_banner';

const Container = (props) => {
  const {
    my_dashboard_url,
    my_work_url,
    our_plans_url,
    my_overview_project_plan_url,
    my_project_plan_default_task_path,
    progress_monitoring_url,
    care_tool_url,
    surveys_url,
    user_belongs_to_multiple_orgs,
    has_default_plan,
    has_current_user,
    has_org,
    home_active,
    can_view_plan,
    can_view_our_plans,
    can_view_progress_monitoring,
    can_view_care,
    can_view_surveys,
    my_work_active,
    our_plans_active,
    scorecard_active,
    strategic_plan_active,
    progress_monitoring_active,
    care_active,
    surveys_active,
    org_name,
    current_user_name,
    current_user_email,
    edit_account_url,
    has_admin_access,
    has_required_authenticator,
    current_user_is_superadmin,
    admin_url,
    logout_url,
    help_guide_url,
    is_mimicking,
    stop_mimicking_url,
    is_staging_environment,
    data_models_manager_url,
  } = props;

  const PlanDependentLinks = () => {
    return (
      <>
        {can_view_plan && (
          <Link href={my_work_url} sx={linkStyle} className={my_work_active ? 'is-active' : ''}>
            My Work
          </Link>
        )}
        {can_view_our_plans && (
          <Link href={our_plans_url} sx={linkStyle} className={our_plans_active ? 'is-active' : ''}>
            Our Plans
          </Link>
        )}
        {can_view_plan && (
          <Link href={my_overview_project_plan_url} sx={linkStyle} className={scorecard_active ? 'is-active' : ''}>
            Scorecard
          </Link>
        )}
        {can_view_plan && (
          <Link href={my_project_plan_default_task_path} sx={linkStyle} className={strategic_plan_active ? 'is-active' : ''}>
            Strategic Plan
          </Link>
        )}
        {can_view_progress_monitoring && progress_monitoring_url && (
          <Link href={progress_monitoring_url} sx={linkStyle} className={progress_monitoring_active ? 'is-active' : ''}>
            Progress Monitoring
          </Link>
        )}
        {can_view_care && care_tool_url && (
          <Link href={care_tool_url} sx={linkStyle} className={care_active ? 'is-active' : ''}>
            CARE Tool
          </Link>
        )}
        {can_view_surveys && surveys_url && (
          <Link href={surveys_url} sx={linkStyle} className={surveys_active ? 'is-active' : ''}>
            Surveys
          </Link>
        )}
      </>
    );
  };

  const UserDependentLinks = () => {
    return (
      <>
        {has_org && can_view_care && care_tool_url && (
          <Link href={care_tool_url} sx={linkStyle} className={care_active ? 'is-active' : ''}>
            CARE Tool
          </Link>
        )}
        {has_org && can_view_surveys && surveys_url && (
          <Link href={surveys_url} sx={linkStyle} className={surveys_active ? 'is-active' : ''}>
            Surveys
          </Link>
        )}
      </>
    );
  };

  const MainAppBar = styled(AppBar)({
    backgroundColor: '#193962',
    color: '#fff',
    height: '60px',
    zIndex: 1020,
    position: 'fixed',
  });

  const CustomToolbar = styled(Toolbar)(({ theme }) => ({
    height: '60px !important',
    paddingLeft: '3rem !important',
    paddingRight: '3rem !important',
    alignItems: 'center',
    '@media (min-width:600px)': {
      minHeight: '60px !important',
    },
    '@media (orientation: landscape)': {
      minHeight: '60px !important',
    },
  }));

  const linkStyle = {
    fontSize: '20px',
    color: '#67bbb9',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      color: '#67bbb9',
    },
    '&.is-active': {
      color: '#fff',
    },
  };

  const getContainerStyle = (isMimicking, isStaging) => {
    let baseHeight = 60;
    let additionalHeight = 0;

    if (isMimicking) {
      additionalHeight += 50;
    }
    if (isStaging) {
      additionalHeight += 50;
    }

    let totalHeight = `${baseHeight + additionalHeight}px`;
    return {
      height: totalHeight,
      position: 'sticky',
      top: '0',
      zIndex: 1020,
    };
  };
  const getMainAppBarOffset = (isMimicking, isStaging) => {
    let offset = 0;

    if (isMimicking) {
      offset += 50;
    }
    if (isStaging) {
      offset += 50;
    }

    return {
      top: `${offset}px !important`,
    };
  };

  return (
    <div style={getContainerStyle(is_mimicking, is_staging_environment)}>
      {(is_staging_environment || true) && <StagingBanner />}
      {is_mimicking && (
        <MimicBanner
          name={current_user_name}
          email={current_user_email}
          stopMimickUrl={stop_mimicking_url}
          offset={is_staging_environment ? 50 : 0}
        />
      )}
      <MainAppBar component="nav" elevation={0} sx={getMainAppBarOffset(is_mimicking, is_staging_environment)}>
        <CustomToolbar>
          <Box sx={{ flex: 1 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              {user_belongs_to_multiple_orgs && (
                <Link href={my_dashboard_url} sx={linkStyle} className={home_active ? 'is-active' : ''}>
                  My Orgs
                </Link>
              )}
              {has_default_plan ? <PlanDependentLinks /> : has_current_user ? <UserDependentLinks /> : null}
            </Stack>
          </Box>
          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <span>{org_name}</span>
              {has_current_user && (
                <GlobalAccount
                  name={current_user_name}
                  email={current_user_email}
                  accountUrl={edit_account_url}
                  hasAdminAccess={has_admin_access}
                  hasRequiredAuthenticator={has_required_authenticator}
                  isSuperAdmin={current_user_is_superadmin}
                  adminUrl={admin_url}
                  helpGuideUrl={help_guide_url}
                  logoutUrl={logout_url}
                  dataModelsManagerUrl={data_models_manager_url}
                />
              )}
            </Stack>
          </Box>
        </CustomToolbar>
      </MainAppBar>
    </div>
  );
};

export default (props, railsContext) => {
  return () => (
    <ThemeProvider theme={themeAttuned}>
      <Container {...props} />
    </ThemeProvider>
  );
};

Container.propTypes = {
  is_mimicking: PropTypes.bool.isRequired,
  stop_mimicking_url: PropTypes.string,
  is_staging_environment: PropTypes.bool.isRequired,
  has_default_plan: PropTypes.bool.isRequired,
  has_current_user: PropTypes.bool.isRequired,
  has_org: PropTypes.bool.isRequired,
  org_name: PropTypes.string,
  my_dashboard_url: PropTypes.string,
  my_work_url: PropTypes.string,
  our_plans_url: PropTypes.string,
  my_overview_project_plan_url: PropTypes.string,
  my_project_plan_default_task_path: PropTypes.string,
  progress_monitoring_url: PropTypes.string,
  care_tool_url: PropTypes.string,
  surveys_url: PropTypes.string,
  user_belongs_to_multiple_orgs: PropTypes.bool,
  home_active: PropTypes.bool,
  can_view_plan: PropTypes.bool,
  can_view_our_plans: PropTypes.bool,
  can_view_progress_monitoring: PropTypes.bool,
  can_view_care_tool: PropTypes.bool,
  can_view_surveys: PropTypes.bool,
  my_work_active: PropTypes.bool,
  our_plans_active: PropTypes.bool,
  scorecard_active: PropTypes.bool,
  strategic_plan_active: PropTypes.bool,
  progress_monitoring_active: PropTypes.bool,
  care_active: PropTypes.bool,
  surveys_active: PropTypes.bool,
  current_user_name: PropTypes.string,
  current_user_email: PropTypes.string,
  edit_account_url: PropTypes.string,
  has_admin_access: PropTypes.bool,
  has_required_authenticator: PropTypes.bool,
  current_user_is_superadmin: PropTypes.bool,
  admin_url: PropTypes.string,
  logout_url: PropTypes.string,
  help_guide_url: PropTypes.string,
};
