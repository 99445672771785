import React from 'react';
import { styled } from '@mui/material/styles';
import { AppBar } from '@mui/material';
import Alert from '@mui/material/Alert';

const BannerBar = styled(AppBar)({
  height: '50px',
  width: '100%',
  zIndex: 1020,
  position: 'fixed',
  top: 0,
});

const StagingBanner = () => {
  return (
    <BannerBar className="no-print">
      <Alert severity="error" variant="filled" sx={{ borderRadius: 0, height: '50px', alignItems: 'center' }}>
        STAGING ENVIRONMENT
      </Alert>
    </BannerBar>
  );
};

export default StagingBanner;
