import { getHeaders } from '../headers';

export const createApi = (defaultBaseUrl) => {
  return {
    create: async (data) => {
      const response = await fetch(`${defaultBaseUrl}/bulk_create`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
    refuse: async (id) => {
      const response = await fetch(`${defaultBaseUrl}/refuse`, {
        method: 'DELETE',
        headers: getHeaders(),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
  };
};
